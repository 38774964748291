import React, { ReactNode, MouseEventHandler } from "react"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import makeStyles from "@material-ui/core/styles/makeStyles"
import { CustomTheme } from "@theme"
import useCssUtil from "@hooks/use-css-util"
import { FormattedMessage } from "gatsby-plugin-intl"
import useImageByName from "@hooks/use-image-by-name"
import { useRandomImage } from "@hooks/use-random-image"

export type ImageDirection = "left" | "right"

export interface IKeyFeaturesItemProps {
  callToAction?: ReactNode
  image?: string[]
  imageDirection?: ImageDirection
  summary?: ReactNode
  title?: ReactNode
  onClick?: MouseEventHandler
}

const useCss = makeStyles((theme: CustomTheme) => ({
  block: {
    position: "relative",
    zIndex: 1,
    paddingTop: theme.custom.sectionSpacingFluidHalf,
    paddingBottom: theme.custom.sectionSpacingFluidHalf,
    minHeight: 360,
    "&:first-child": {
      paddingTop: 0,
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.custom.sectionSpacingSm,
      paddingBottom: theme.custom.sectionSpacingSm,
    },
  },
  featureImgContainer: {
    width: "100%",
    borderRadius: 10,
  },
  featureImage: {
    width: "100%",
    maxWidth: "100%",
    maxHeight: "450px",
    objectFit: "contain",
    [theme.breakpoints.up("sm")]: {
      maxWidth: "65%",
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: "100%",
    },
  },
  contentContainer: {
    [theme.breakpoints.down("md")]: {
      paddingTop: "0 !important",
    },
  },
  largeText: {
    fontSize: "18px",
  },
}))

export default function KeyFeaturesItem({
  callToAction,
  image,
  imageDirection,
  onClick,
  summary,
  title,
}: IKeyFeaturesItemProps) {
  const classes = useCss()
  const cssUtil = useCssUtil()
  const itemImage = useRandomImage(image!)

  return (
    <Container className={classes.block} maxWidth="xl">
      <Grid
        alignItems="center"
        container
        direction={imageDirection === "right" ? "row-reverse" : "row"}
        spacing={10}
      >
        <Grid item xs={12} md={5}>
          <div className={classes.featureImgContainer}>
            <img className={classes.featureImage} src={itemImage?.src} />
          </div>
        </Grid>

        <Grid item xs={12} md={7} className={classes.contentContainer}>
          <Typography className={cssUtil.mb15} variant="h2">
            {title}
          </Typography>
          <Typography
            color="textSecondary"
            variant="body1"
            className={classes.largeText}
          >
            {summary}
          </Typography>
        </Grid>
      </Grid>
    </Container>
  )
}

KeyFeaturesItem.defaultProps = {
  callToAction: (
    <FormattedMessage
      defaultMessage="Get Started"
      id="components.KeyFeature.featureCtaLabel"
    />
  ),
  imageDirection: "left",
} as Partial<IKeyFeaturesItemProps>
