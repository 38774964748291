import { useStaticQuery, graphql } from 'gatsby';

const query = graphql`
  query AllImages {
    allImageSharp{
      nodes {
        fluid {
          originalName
          src
          srcSet
        }
      }
      totalCount
    }
  }
`;

export interface IFluidImage {
  originalName: string;
  sizes: string;
  src: string;
  srcSet: string;
}

export interface IImagesQueryResult {
  allImageSharp: {
    totalCount: number;
    nodes: Array<{ fluid: IFluidImage }>
  }
}

export default function useImages(): IImagesQueryResult {
  return useStaticQuery(query);
}