import { Review } from "@definitions/Review"
import makeStyles from "@material-ui/core/styles/makeStyles"
import useTheme from "@material-ui/core/styles/useTheme"
import React from "react"
import Slider from "react-slick"
import ReviewsItem from "./ReviewsItem"
import ReviewsRotatorArrow from "./ReviewsRotatorArrow"

const useCss = makeStyles((theme) => ({
  ReviewsRotator: {
    display: "flex",
    position: "relative",
    justifyContent: "space-around",
    [theme.breakpoints.down("xs")]: {
      flexWrap: "wrap",
      justifyContent: "space-between",
    },
    "& .slick-arrow": {
      marginTop: -4,
      maxWidth: "10%",
      padding: "0 12px",
      [theme.breakpoints.down("xs")]: {
        order: 1,
      },
    },
    "& .slick-slide": {
      float: "left",
      padding: "16px 32px 48px",
    },
    "& .slick-list": {
      flex: "1 0 auto",
      maxWidth: "88%",
      overflow: "hidden",
      position: "relative",
      [theme.breakpoints.down("xs")]: {
        order: 0,
        maxWidth: "100%",
      },
    },
  },
}))

export default function ReviewsRotator({
  reviews,
}: Record<"reviews", Review[]>) {
  const theme = useTheme()
  const classes = useCss()

  return (
    <Slider
      arrows
      className={classes.ReviewsRotator}
      dots={false}
      infinite
      slidesToShow={reviews.length > 1 ? 2 : 1}
      slidesToScroll={reviews.length > 1 ? 2 : 1}
      responsive={[
        {
          breakpoint: theme.breakpoints.values.md,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ]}
      nextArrow={<ReviewsRotatorArrow direction="right" />}
      prevArrow={<ReviewsRotatorArrow direction="left" />}
    >
      {reviews.map((review, index) => (
        <ReviewsItem
          author={review.author}
          key={index}
          review={review.message}
        />
      ))}
    </Slider>
  )
}
