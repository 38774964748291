import useImages, { IFluidImage } from './use-images';

export default function useImageByName(imageName: string): IFluidImage | undefined {
  const result = useImages();

  for (let i = 0; i < result.allImageSharp.totalCount; i++) {
    const node = result.allImageSharp.nodes[i];

    if (node.fluid.originalName === imageName) {
      return node.fluid;
    }
  }
}
