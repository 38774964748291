import { Review } from "@definitions/Review"
import { either } from "tiinvo"
import * as client from "./client"

const review = client.create(client.baseURL(`/api/review`))

const getbyidrequest = (id: string) => review.get(`/${id}`)
const listrequest = (params: { limit?: number } = {}) =>
  review.get(``, { params })
const postrequest = (id: string, data: Review) => review.post(`/${id}`, data)

const getbyidhandler = client.mapresponse<
  unknown,
  { valid: boolean; review: Review | null }
>({
  200: either.right,
  400: either.left,
  500: either.left,
})

const listhandler = client.mapresponse<
  unknown,
  { count: number; page: Review[] }
>({
  200: either.right,
  400: either.left,
  500: either.left,
})

const posthandler = client.mapresponse<unknown, unknown>({
  200: either.right,
  400: either.left,
  500: either.left,
})

export const getbyid = client.withstatushandler(getbyidhandler)(getbyidrequest)
export const list = client.withstatushandler(listhandler)(listrequest)
export const post = client.withstatushandler(posthandler)(postrequest)
