import { Review } from "@definitions/Review"
import * as review from "@sdk/review"
import { useEffect, useState } from "react"
import { either } from "tiinvo"

export type useReviews = {
  canloadmore: boolean
  reviews: Review[]
}

export default function useReviews(limit: number = 20): useReviews {
  const [reviews, setreviews] = useState<Review[]>([])
  const [itemscount, setitemscount] = useState(limit)
  const [loaded, setloaded] = useState(0)
  const [firstload, setfirstload] = useState(false)
  const canloadmore = firstload ? loaded < itemscount : true
  const load = (limit?: number) =>
    review.list({ limit }).then((res) => {
      if (either.isRight(res)) {
        const { count, page } = res.value
        setitemscount(count)
        setreviews(page)
        setfirstload(true)
        setloaded(loaded + page.length)
      }
    })

  useEffect(() => {
    if (canloadmore) {
      load(limit)
    }
  }, [limit, canloadmore])

  return {
    canloadmore,
    reviews,
  }
}
