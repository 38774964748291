import makeStyles from "@material-ui/core/styles/makeStyles"
import Typography from "@material-ui/core/Typography"
import React from "react"

export interface IReviewsItemProps {
  author?: string
  review?: string
}

const useCss = makeStyles((theme) => ({
  reviewBox: {
    display: "flex",
    flexDirection: "column",
    padding: "50px 40px 30px",
    height: 235,
    backgroundImage: "url(/images/quote-icon.svg)",
    backgroundSize: "94px 89px",
    backgroundPosition: "30px 30px",
    backgroundRepeat: "no-repeat",
    backgroundColor: "white",
    borderRadius: 20,
    boxShadow: "0 15px 30px 0 rgba(1,1,13, 0.1)",
    [theme.breakpoints.down("xs")]: {
      height: "auto",
    },
  },
  quote: {
    fontSize: "1.2rem",
    marginBottom: 15,
    // maxHeight: "6em",
  },
  person: {
    textAlign: "right",
    fontSize: "1.2rem",
  },
}))

const truncate = (input: string = "") => {
  if (input.length > 120) {
    return input.substring(0, 120) + " ..."
  }

  return input
}

export default function ReviewsItem(props: IReviewsItemProps) {
  const classes = useCss()

  return (
    <article className={classes.reviewBox}>
      <Typography
        variant="body1"
        color="textSecondary"
        className={classes.quote}
      >
        “{truncate(props.review)}”
      </Typography>
      <Typography variant="body1" className={classes.person}>
        <strong>- {props.author}</strong>
      </Typography>
    </article>
  )
}
