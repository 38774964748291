import React from "react"
import makeStyles from "@material-ui/core/styles/makeStyles"
import { defineMessages, useIntl } from "gatsby-plugin-intl"
import clsx from "clsx"

export type ReviewsRotatorArrowDirection = "left" | "right"

export interface IReviewsRotatorArrowProps {
  className?: string
  direction: ReviewsRotatorArrowDirection
  onClick?: React.MouseEventHandler
}

const messages = defineMessages({
  left: {
    defaultMessage: "Previous",
    id: "components.ReviewsRotatorArrow.left",
  },
  right: {
    defaultMessage: "Next",
    id: "components.ReviewsRotatorArrow.right",
  },
})

const useCss = makeStyles({
  ReviewsRotatorArrow: {
    cursor: "pointer",
  },
})

function getMessage(direction: ReviewsRotatorArrowDirection) {
  switch (direction) {
    case "left":
      return messages.left
    case "right":
      return messages.right
  }
}

function getSrc(direction: ReviewsRotatorArrowDirection): string {
  switch (direction) {
    case "left":
      return "/images/gold-left-arrow.svg"
    case "right":
      return "/images/gold-right-arrow.svg"
  }
}

export default function ReviewsRotatorArrow(props: IReviewsRotatorArrowProps) {
  const classes = useCss()
  const intl = useIntl()
  const alt = getMessage(props.direction)
  const src = getSrc(props.direction)

  return (
    <img
      alt={intl.formatMessage(alt)}
      className={clsx(classes.ReviewsRotatorArrow, props.className)}
      onClick={props.onClick}
      src={src}
    />
  )
}
