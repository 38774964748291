import React from "react"
import { Container, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core"
import { CustomTheme } from "@theme"
import { FormattedMessage, Link } from "gatsby-plugin-intl"
import ReviewsRotator from "./ReviewsRotator"
import useReviews from "@hooks/use-reviews"
import { array } from "tiinvo"
import Button from "./Button"

const useCss = makeStyles((theme: CustomTheme) => ({
  container: {
    marginBottom: theme.custom.sectionSpacingFluid,
    marginTop: theme.custom.sectionSpacingFluid,
    [theme.breakpoints.up("md")]: {
      marginBottom: theme.custom.sectionSpacingLg,
      marginTop: theme.custom.sectionSpacingMd,
    },
  },
  ctacontainer: {
    marginTop: theme.custom.sectionSpacingFluid,
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      marginTop: 0,
    },
  },
  reviewContainer: {
    marginTop: theme.custom.sectionSpacingFluidHalf,
    [theme.breakpoints.up("md")]: {
      marginTop: "32px",
    },
  },
  reviewBox: {
    display: "flex",
    flexDirection: "column",
    padding: "50px 40px 30px",
    height: 235,
    backgroundImage: "url(/images/quote-icon.svg)",
    backgroundSize: "94px 89px",
    backgroundPosition: "30px 30px",
    backgroundRepeat: "no-repeat",
    backgroundColor: "white",
    borderRadius: 20,
    boxShadow: "0 15px 30px 0 rgba(1,1,13, 0.1)",
  },
  quote: {
    fontSize: "1.2rem",
    marginBottom: 15,
  },
  person: {
    textAlign: "right",
    fontSize: "1.2rem",
  },
}))

const limit = array.takefirstnth(10)

export default function Reviews() {
  const classes = useCss()
  const { reviews } = useReviews()

  if (reviews.length === 0) {
    return null
  }

  return (
    <section className={classes.container}>
      <Container maxWidth="sm">
        <Typography align="center" variant="h2" component="h2">
          <FormattedMessage
            defaultMessage="Totenpass Reviews"
            id="components.Reviews.title"
          />
        </Typography>
      </Container>

      <Container maxWidth="lg" className={classes.reviewContainer}>
        <ReviewsRotator reviews={limit(reviews)} />
      </Container>
      {reviews.length > 2 && (
        <Container maxWidth="lg" className={classes.ctacontainer}>
          <Link to="/reviews" style={{ textDecoration: "none" }}>
            <Button>
              <FormattedMessage
                defaultMessage="Read more reviews"
                id="components.Reviews.cta"
              />
            </Button>
          </Link>
        </Container>
      )}
    </section>
  )
}
