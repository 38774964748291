import {
  Button,
  Dialog,
  DialogContent,
  IconButton,
  makeStyles,
} from "@material-ui/core"
import { CloseSharp } from "@material-ui/icons"
import { FormattedMessage } from "gatsby-plugin-intl"
import React, { FC, useState } from "react"
import YouTube from "react-youtube"

export interface VideoProps {}

const titlehmultiplier = 6

const usecss = makeStyles((theme) => ({
  ctaButton: {
    marginLeft: 15,
    marginRight: 15,
  },
  modalcontent: {
    padding: `0 !important`,
  },
  videodialog: {
    height: `100vh`,
  },
  modaltitle: {
    alignItems: `center`,
    backgroundColor: `#000`,
    color: `#fff`,
    display: `flex`,
    justifyContent: `space-between`,
    padding: theme.spacing(0, 2),
    height: theme.spacing(titlehmultiplier),
  },
  video: {
    height: `calc(100vh - ${theme.spacing(titlehmultiplier)}px)`,
    width: "100%",
  },
}))

export const Video: FC<VideoProps> = ({}) => {
  const [open, setopen] = useState(false)
  const setopentrue = () => setopen(true)
  const setopenfalse = () => setopen(false)
  const classes = usecss()

  return (
    <>
      <Button
        onClick={setopentrue}
        variant="contained"
        color="secondary"
        className={classes.ctaButton}
      >
        <FormattedMessage
          defaultMessage="Watch the Video"
          id="components.Video.cta.right"
        />
      </Button>
      <Dialog
        open={open}
        onBackdropClick={setopenfalse}
        onClose={setopenfalse}
        fullScreen
      >
        <DialogContent className={classes.modalcontent}>
          <div className={classes.modaltitle}>
            <IconButton color="inherit" onClick={setopenfalse}>
              <CloseSharp />
            </IconButton>
          </div>
          <YouTube
            className={classes.video}
            opts={{
              playerVars: {
                autoplay: open ? 1 : 0,
                showinfo: 0,
              },
            }}
            videoId="5JCC8xG2RmU"
          />
        </DialogContent>
      </Dialog>
    </>
  )
}
